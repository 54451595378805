 export class sunnySDK {
    constructor() {}
  sdkSettings = {
      env: "dev",
      partnerCode: "",
      memberNbr: "",
      email: "",
      width: 412,
      height: 915,
      apiToken:"",
      notifyParent:"",
      parentElementId: null, // Add parentElementId property
      callbacks: {
        onClose: function (payload) {
          // Anything after closing, typically navigation
        },
      },
    };
  
    init() {
      var ifr = document.getElementById("ifr1");
      if (ifr) {
        // delete any existing widget
        ifr.remove();
      }
  
      // Create the iframe element
      const iframe = document.createElement("iframe");
      iframe.setAttribute("id", "ifr1");
      let baseUrl = "http://localhost:4002";
      if (this.sdkSettings.env === "dev") {
        baseUrl = "https://app.dev.sunnyrewards.com";
      } else if (this.sdkSettings.env === "qa") {
        baseUrl = "https://app.qa.sunnyrewards.com";
      } else if (this.sdkSettings.env === "prod") {
        baseUrl = "https://app.sunnyrewards.com";
      }else if (this.sdkSettings.env === "integ") {
        baseUrl = "https://app.integ.sunnyrewards.com";
      }else if (this.sdkSettings.env === "uat") {
        baseUrl = "https://app.uat.sunnyrewards.com";
      }
  
      // Set the source URL of the iframe
      if (this.sdkSettings.consumerCode) {
        iframe.src =
          baseUrl + "/sdk?consumer-code=" + this.sdkSettings.consumerCode;
      } else if (this.sdkSettings.partnerCode && this.sdkSettings.memberNbr) {
        iframe.src =
          baseUrl +
          "/sdk?partner-code=" +
          this.sdkSettings.partnerCode +
          "&mem-nbr=" +
          this.sdkSettings.memberNbr
      }  else {
        console.error("Invalid arguments");
        return;
      }
      if (this.sdkSettings.apiToken) {
        iframe.src = iframe.src+"&apiToken=" + this.sdkSettings.apiToken;
      }
      if (this.sdkSettings.notifyParent) {
        iframe.src = iframe.src+"&notifyParent=" + this.sdkSettings.notifyParent;
      }
   
      // Set the width and height of the iframe (optional)
      iframe.width = this.sdkSettings.width;
      iframe.style.height = "100%";
      iframe.frameBorder = "0";
    
  
      // Append the iframe to an existing element on your webpage
      let parentElement = null;
      if (this.sdkSettings.parentElementId) {
        parentElement = document.getElementById(this.sdkSettings.parentElementId);
      }
      const parent = parentElement || document.body;
      parent.appendChild(iframe);
      window.addEventListener("message", (event) => {
        if (event.data.message === "onCloseEvent") {
          if (this.sdkSettings.callbacks && this.sdkSettings.callbacks.onClose) {
            this.sdkSettings.callbacks.onClose();
          }
        }
      });
    }
  }

sunnySDK.sdkSettings = undefined;
